@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');


* {
    font-family: 'Montserrat', sans-serif;
}

:root {
    --background-color: #ffffff;
    --text-color: #16423C;
    --grey-color: #4A4947;
    --primary-color: #DA6E27;
    --secondary-color: #3CB54A;
    --success-color: #00a202;
    --danger-color: #e00027;
    --warning-color: #c78100;

    --fine-gray: #e7e7e7;
    --fine-gray-light: #f1f1f1;
    --fine-gray-dark: #dddddd;
    --fine-gray-very-dark: #b9b9b9;


    --default-width: 1200px;
    --navbar-height: 80px;
    --footer-height: 40px;
    font-family: 'Montserrat', sans-serif;
}


body {
    display: flex;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: var(--text-color);
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    width: 100vw;
    justify-content: center;
    align-items: center;

    height: calc(100%-80px);
    margin-bottom: 50px;
    background-color: var(--text-color);
}



a:link,
a:visited {
    color: inherit;
    text-decoration: none;
    font-size: 1.1em;
}

body::-webkit-scrollbar {
    display: none;
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    min-height: calc(100vh - 120px);
    overflow: auto;
    flex-grow: 1;
    width: 100%;
}

h1 {
    font-size: 1.6em;
    font-weight: 700;
    color: var(--text-color);
}

h2 {
    font-size: 1.5em;
    font-weight: 700;
    color: var(--text-color);
}

h3 {
    font-size: 1.4em;
    font-weight: 700;
    color: var(--text-color);
}

h4 {
    font-size: 1.3em;
    font-weight: 700;
    color: var(--text-color);
}

h5 {
    font-size: 1.2em;
    font-weight: 700;
    color: var(--text-color);
}





.phone {
    display: flex;
    color: var(--primary-color);
    font-size: 1.1em;
    font-weight: 700;
    align-items: center;
}



.logo-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.logo {
    height: var(--navbar-height);
    padding-right: 50px;
    height: 70px;
    padding-top: 5px;
}

.initials {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.1em;
    font-weight: 700;
    color: var(--background-color);
    background-color: var(--secondary-color);
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-left: 30px;
}

.footer {
    font-family: 'Merriweather', serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(45, 64, 89, 0.36);
    color: var(--background-color);
    font-size: 0.8em;

    height: var(--footer-height);
    position: fixed;
    bottom: 0;
    flex-shrink: 0;
    margin-top: 50px;
    gap: 50px;
}

.footer div {
    padding: 10px;
}

.footer a {
    text-decoration: underline;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background-color: var(--mainColor);
    color: var(--textColor);
    z-index: 1;
    font-size: 1.4em;
}

nav a {
    margin: 0 1.5rem;
    color: var(--textColor);
    text-decoration: none;

    font-weight: 700;



}




header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--background-color);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

header div,
nav {
    display: flex;
    align-items: center;
}

.button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.button-style {
    height: 50px;
    width: 200px;
    font-size: 1em;
    font-weight: 700;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: px solid var(--text-color);
}



.button-cta {
    background-color: var(--primary-color);
    color: var(--background-color);
}

.custom-shape-divider-top-1729358274 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;

}

.custom-shape-divider-top-1729358274 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 220px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1729358274 .shape-fill {
    fill: var(--background-color);
}

.button-style:hover {

    color: var(--text-hover-color);
    transform: scale(0.95);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.developer-link {
    border: 1px solid var(--background-color);
    border-radius: 5px;
    padding: 3px 8px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.developer-link:hover {
    background-color: var(--background-color);
    /* Background changes on hover */
    color: var(--text-color);
    /* Text color changes on hover */
}

@media (max-width: 1200px) {

    header .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    header nav {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 1s;
    }

    .logo-div {
        height: auto;
        padding: 1rem;
        margin: 1rem 0;
    }

    .logo {

        width: 100%;
        filter: drop-shadow(1px 1px 1px var(--background-color));
    }

    header .responsive_nav {
        transform: translateY(100vh);
        background-color: var(--text-color);
        color: var(--background-color);
        z-index: 1000;
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav a {
        font-size: 1.5rem;
    }

    .footer {
        font-size: 5px;
        gap: 5px;
    }

    .footer a {
        text-decoration: underline;
    }

    .custom-shape-divider-top-1729358274 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 120px;
        transform: rotateY(180deg);
    }

    .button-style {
        max-width: 40vw;
        margin-bottom: 20px;
    }

}