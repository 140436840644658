.testimonials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - var(--navbar-height) - var(--footer-height) - 40px);

}

.section-header {
    text-align: center;
    margin-bottom: 50px;
}

.section-header h2 {
    color: var(--primary-color);
    margin: 0;
    font-size: 32px;
}

.section-header p {
    color: var(--background-color);
    margin-top: 10px;
}

.testimonials-grid {
    display: grid;
    grid-template-columns: 350px 350px 350px;
    gap: 40px;
    padding: 20px;
}

.testimonial-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--background-color);
    border-radius: 40px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    height: 300px;
}

.quote {
    color: var(--text-color);
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 60px;
    text-align: justify;
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
}

.profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.profile-name {
    font-weight: bold;
    margin: 10px 0;
    color: var(--text-color);
}

.social-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* Centers horizontally */
    align-items: center;
    gap: 20px;
    color: var(--background-color);
    text-decoration: none;
    margin-right: 10px;
    margin-bottom: 5px;
}

.icon-testimonial {
    font-size: 1.2em;
    color: var(--text-color);
}

.wave-1 {
    position: absolute;
    bottom: -150px;
    left: 0;
    width: 100%;
    height: 350px;
    background: var(--primary-color);
    clip-path: path('M 0 41 C 208 -29 269 174 350 160 L 350 210 L 0 210 Z');
}

/* Second card starts at 80px and ends at 60px */
.wave-2 {
    position: absolute;
    bottom: -150px;
    left: 0;
    width: 100%;
    height: 350px;
    background: var(--secondary-color);
    clip-path: path('M 0 160 C 70 146 292 92 350 111 L 350 210 L 0 210 Z');
}

/* Third card starts at 60px */
.wave-3 {
    position: absolute;
    bottom: -150px;
    left: 0;
    width: 100%;
    height: 350px;
    background: var(--primary-color);
    clip-path: path('M 0 111 C 75 130 279 181 350 141 L 350 210 L 0 210 Z');
}

.testimonial-card:nth-child(2) .wave {
    background: #8B7FD5;
}

.testimonial-card:nth-child(3) .wave {
    background: #2196F3;
}


@media (max-width: 1200px) {

    .quote {
        color: var(--text-color);
        font-size: 1em;
        text-align: justify;
        height: 100px;
        white-space: normal;
    }

    .testimonials-grid {
        display: none;
    }

    .testimonials-slider {
        width: 350px;
        margin: 0 auto;
    }

    .testimonial-slide {
        width: 350px !important;
        height: auto !important;
    }

    /* Override image gallery styles for testimonials */
    .image-gallery-slide {
        background: transparent;
    }

    .image-gallery-content {
        width: 100%;
    }

    .image-gallery-slide-wrapper {
        background: transparent;
    }

    .image-gallery-bullets {
        bottom: -20px;
    }

    .image-gallery-bullet {
        background-color: var(--background-color);
        border: 1px solid var(--primary-color);
    }

    .image-gallery-bullet.active {
        background-color: var(--primary-color);
    }

    .hidden {
        display: none;
    }

    /* Adjust testimonial card for mobile */
    .testimonial-card {
        margin: 0 auto;
        height: auto;
        min-height: 300px;
    }

    .section-header p {
        min-width: 100%;
    }
}