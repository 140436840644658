.contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: calc(100vh - var(--navbar-height) - var(--footer-height) - 40px);
    gap: 20px;
}

h1 {
    color: var(--background-color);
    font-size: 3em;
}

.form-container {
    display: flex;
    flex-direction: column;
    height: 90%;
    gap: 10px;
}

.contact-form {

    gap: 10px;
}

.small-field,
.large-field {
    width: 40vw;
    height: 50px;
    border-radius: 20px;
    cursor: pointer;
    padding: 0px 20px;
    margin-bottom: 15px;
    font-size: 1.2em;
}



.large-field {
    height: 100px;
    padding: 20px 20px;
}

.get-in-touch-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 90%;
    color: var(--background-color);
}

.git-section {
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-size: 1.5em;
    align-items: center;
    height: 70px;
}

.icon {
    font-size: 1.5em;

}

.map-container {
    width: 40vw;
    height: 300px;
}

.privacy-policy {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 5px;
    color: var(--background-color);
    font-size: 1em;
    margin-bottom: 15px;
    padding: 5px;
}

.privacy-link {
    color: var(--background-color);
    text-decoration: underline;
    cursor: pointer;
    pointer-events: auto;
    z-index: 10;
}


.privacy-policy a {
    color: var(--background-color);
    font-size: 1em;
    text-decoration: underline;
    cursor: pointer;
}

.privacy-policy input[type="checkbox"] {

    margin-top: 4px;
    /* Adds slight offset to align with first line of text */
}

.privacy-policy label {
    font-size: 0.85em;
    line-height: 1.4;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: var(--background-color);
    color: var(--text-color);
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 80%;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-close {
    margin-top: 20px;
    background: var(--text-color);
    color: white;
    font-weight: 700;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.modal-close:hover {
    background: #0056b3;
}


@media (max-width: 1200px) {

    .contact {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        width: 80vw;
    }


    .form-container,
    .get-in-touch-container {
        align-items: center;
        height: auto;
        width: 80vw;
    }

    h1 {
        font-size: 3em;

    }


    .small-field,
    .large-field {

        width: 68vw;
        height: 40px;
        border-radius: 20px;
        cursor: pointer;
        padding: 0px 20px;
        margin-bottom: 0px;
        font-size: 1.1em;
    }


    .large-field {
        height: 100px;
        padding: 20px 20px;
    }

    .contact-form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .map-container {
        width: 80vw;
        height: 300px;
    }

    .git-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        font-size: 1.5em;
        align-items: center;
        width: 80vw;
        height: auto;
    }

    .sadsad {
        font-size: 0.8em;
    }



    .icon {
        font-size: 1.5em;


    }

    .privacy-policy {
        width: 90%;
        overflow-wrap: normal;
        height: auto;
        padding-left: 20px;

    }

}