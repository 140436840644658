.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.about-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: var(--default-width);

    font-size: 1.2em;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 50px;
}

h3 .reveal {
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    width: 100vw;
    height: 100vh;
}

.image1 {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    width: 100vw;
    height: 100vh;
}

.content {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    position: relative;
    width: auto;

    background-color: var(--text-color);
    text-transform: uppercase;
    color: var(--background-color);
    padding: 1em;
    font-size: 1.5em;
    letter-spacing: 5px;
}

.content span.img-text {
    background-color: var(--text-color);
    text-transform: uppercase;
    color: var(--text-color);
    padding: 1em;
    font-size: 1.5em;
    letter-spacing: 5px;
}

.about-desc p {
    text-align: justify;
    color: var(--background-color);
    font-size: 0.9em;
}

.about-desc h3 {
    text-align: center;
    color: var(--background-color);
    width: 100%;
    display: flex;
    justify-content: center;
}

.service-text {
    color: var(--background-color);
    text-align: justify;
}



nav a {
    color: var(--background-color);
}


@media (max-width: 1200px) {

    .about-desc {
        width: 90vw;
    }

    .explainer-text {
        text-align: justify;
        color: var(--background-color);
        font-size: 0.6em;
    }







}