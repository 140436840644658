.privacy {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--navbar-height) - var(--footer-height) - 40px);
    z-index: 1;
    width: 80vw;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    overflow-x: auto;
}

.privacy h1 {
    color: var(--background-color);
    font-size: x-large;
}

.privacy h2 {
    color: var(--background-color);
    font-size: large;
    padding-left: 10px;
}

.privacy p {
    color: var(--background-color);
    padding-left: 30px;
}

.privacy a {
    font-size: 1em;
    text-decoration: underline;
}