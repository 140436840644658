.home {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100vh - var(--navbar-height) - var(--footer-height) - 40px);
    gap: 60px;
    z-index: 1;
    width: 80vw;
}



.team {
    height: 100%;
    width: auto;
    align-content: center;
}

.team-pic {
    vertical-align: middle;
    width: auto;
    max-width: 40vw;
}

.desc-container {
    display: flex;
    flex-direction: column;
    width: auto;
    justify-content: center;
    gap: 30px;
    align-items: center;
}

.desc-header {
    font-family: 'Merriweather', serif;
    font-size: 2.1em;
    font-weight: 700;

    color: var(--background-color);
    text-align: center;
}

.header-text {
    justify-content: center;
    text-align: center;
}

.welcome h1 {
    font-size: 1em;
}

.desc-text {
    max-width: 500px;
    text-align: justify;
    color: var(--background-color);

    font-family: 'Merriweather', serif;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 130%;
}



.welcome {
    display: flex;
    box-direction: row;
    font-size: 1.1em;
    gap: 10px;

}

.prim-colored {
    color: var(--primary-color);
    font-size: inherit;
}

.home-mobile {
    display: none;
}

.main-container,
.footer {
    background-color: var(--text-color);
}



@media (max-width: 1200px) {



    .home {
        display: none;
    }

    .home-mobile {
        display: flex;
        flex-direction: column;
        width: calc(100vw-50px);
        gap: 30px;
        align-items: center;
    }

    .desc-header {

        font-size: 1.8em;

    }

    .welcome {

        text-align: center;
    }

    .desc-header {
        margin-top: 10px;
    }

    .desc-text {
        max-width: 80vw;
        font-size: 1em;
    }

    .team-pic {
        width: 90vw;
    }

    .header-text {
        max-width: 80vw;
    }



}